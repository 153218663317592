import { icon } from 'leaflet'

export default {
	main: {
		home: {
			icon: 'home',
		},
		bonuses: {
			icon: 'battery-charging',
			children: {
				bonusSettings: {
					icon: 'circle',
				},
				bonusHistory: {
					icon: 'circle',
				},
			},
		},
		orders: {
			icon: 'shopping-cart',
		},
		customers: {
			icon: 'user',
		},
		statistics: {
			icon: 'activity',
		},
		products: {
			icon: 'shopping-bag',
		},
		stores: {
			icon: 'flag',
		},
		stocks: {
			icon: 'table',
		},
		groups: {
			icon: 'check-square',
		},
		categories: {
			icon: 'check-square',
		},
		promotions: {
			icon: 'star',
		},
		contents: {
			icon: 'book-open',
		},
		messages: {
			icon: 'message-circle',
		},
		chats: {
			icon: 'message-square',
		},
		settings: {
			icon: 'settings',
		},
		users: {
			icon: 'user-check',
		},
		notifications: {
			icon: 'message-square',
		},
		delivery: {
			icon: 'truck',
		},
		import: {
			icon: 'download',
		},
		rewards: {
			icon: 'gift',
		},
		jettons: {
			icon: 'award',
		},
	},

	account: {
		profile: {
			icon: 'user',
		},
		logout: {
			icon: 'power',
			action: 'logout',
		},
	},
}
